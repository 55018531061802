import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,

    data: {
      id: '',
      golongan: {
        id: '',
        nama: '',
      },
      noperbkn: '',
      noperbkn_tgl: '',
      sk_pengangkatan: '',
      sk_tgl: '',
      status_pengangkatan: '',
      alamat: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.data = value
    },
  }
})