<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Tambah Riwayat Pengangkatan
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Nota Persetujuan BKN</span>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                v-model="noperbkn"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5">
              <v-menu
                ref="pick_noperbkn"
                v-model="pick_noperbkn"
                :close-on-content-click="false"
                :return-value.sync="noperbkn_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="noperbkn_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="noperbkn_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_noperbkn = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_noperbkn.save(noperbkn_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Pejabat Yang Menetapkan</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                v-model="pejabat"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Surat Keputusan (SK)</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="sk_pengangkatan"
                class="mt-2"
                hint="Nomor"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                ref="pick_sktgl"
                v-model="pick_sktgl"
                :close-on-content-click="false"
                :return-value.sync="sk_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sk_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="sk_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_sktgl = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_sktgl.save(sk_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>TMT PPPK</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-menu
                ref="pick_tmt"
                v-model="pick_tmt"
                :close-on-content-click="false"
                :return-value.sync="tmt_pppk"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tmt_pppk"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tmt_pppk" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_tmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_tmt.save(tmt_pppk)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Golongan</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-autocomplete
                v-model="golongan_id"
                :items="refGolongan"
                item-value="id"
                outlined
                dense
              >
                <template slot="selection" slot-scope="data">
                  Golongan - {{ data.item.nama }}
                </template>
                <template slot="item" slot-scope="data">
                  Golongan - {{ data.item.nama }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Status Pengangkatan</span>
            </v-col>

            <v-col cols="12" md="10">
              <v-select
                v-model="status_pengangkatan"
                class="mt-2"
                :items="stat_peng"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" class="mt-3">
              <span>Upload Dokumen</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Nomor SKCK</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="skck_nomor"
                class="mt-2"
                hint="Nomor"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                ref="pick_skck"
                v-model="pick_skck"
                :close-on-content-click="false"
                :return-value.sync="skck_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="skck_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="skck_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_skck = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_skck.save(skck_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onskck"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Nomor Suket Sehat</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="suketsehat_nomor"
                class="mt-2"
                hint="Nomor"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                ref="pick_suketsehat"
                v-model="pick_suketsehat"
                :close-on-content-click="false"
                :return-value.sync="suketsehat_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="suketsehat_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="suketsehat_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_suketsehat = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_suketsehat.save(suketsehat_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onsuketsehat"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" class="mt-3">
              <span>SK BKN</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="skbkn_nomor"
                class="mt-2"
                hint="Nomor"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                ref="pick_skbkn"
                v-model="pick_skbkn"
                :close-on-content-click="false"
                :return-value.sync="skbkn_tgl"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="skbkn_tgl"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="skbkn_tgl" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pick_skbkn = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pick_skbkn.save(skbkn_tgl)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onskbkn"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <div class="text-right">
            <v-btn
              color="primary"
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refresh from "@/store/asn/pppk/rwpengangkatan/view";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    ModalAdd: false,
    lbtn: false,

    refGolongan: [],
    stat_peng: [
      {
        id: "pertama",
        nama: "Pertama",
      },
      {
        id: "perpanjangan",
        nama: "Perpanjangan",
      },
    ],

    get_nip: "",

    pick_noperbkn: false,
    pick_sktgl: false,
    pick_tmt: false,
    pick_skck: false,
    pick_suketsehat: false,
    pick_skbkn: false,

    noperbkn: "",
    noperbkn_tgl: "",
    pejabat: "",
    sk_pengangkatan: "",
    sk_tgl: "",
    tmt_pppk: "",
    golongan_id: "",
    status_pengangkatan: "",
    skck_nomor: "",
    skck_tgl: "",
    suketsehat_nomor: "",
    suketsehat_tgl: "",
    skbkn_nomor: "",
    skbkn_tgl: "",
    doc: "",
    doc_skck: "",
    doc_suketsehat: "",
    doc_skbkn: "",

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refGolongan = await getRef.PPPK_Golongan(this.token);
      }
    },
  },

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {},

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url =
          process.env.VUE_APP_ASN + "pppk/pengangkatan/nip/" + this.get_nip;

        const data = new FormData();
        data.append("doc_pengangkatan", this.doc);
        data.append("doc_skck", this.doc_skck);
        data.append("doc_suketsehat", this.doc_suketsehat);
        data.append("doc_skbkn", this.doc_skbkn);
        data.append("noperbkn", this.noperbkn);
        data.append("noperbkn_tgl", this.noperbkn_tgl);
        data.append("pejabat", this.pejabat);
        data.append("sk_pengangkatan", this.sk_pengangkatan);
        data.append("sk_tgl", this.sk_tgl);
        data.append("tmt_pppk", this.tmt_pppk);
        data.append("golongan_id", this.golongan_id);
        data.append("status_pengangkatan", this.status_pengangkatan);
        data.append("skck_nomor", this.skck_nomor);
        data.append("skck_tgl", this.skck_tgl);
        data.append("suketsehat_nomor", this.suketsehat_nomor);
        data.append("suketsehat_tgl", this.suketsehat_tgl);
        data.append("skbkn_nomor", this.skbkn_nomor);
        data.append("skbkn_tgl", this.skbkn_tgl);

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refresh.commit("refreshData", true);
              refresh.commit("alert", response.data.message);
              refresh.commit("berhasilAlert", true);
              refresh.commit("gagalAlert", false);
              refresh.commit("success", response.data.success);
            } else {
              refresh.commit("refreshData", true);
              refresh.commit("alert", response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refresh.commit("refreshData", true);
              refresh.commit("alert", error.response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refresh.commit("refreshData", true);
              refresh.commit("alert", error.response.data.message);
              refresh.commit("gagalAlert", true);
              refresh.commit("berhasilAlert", false);
              refresh.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    onUpload(value) {
      this.doc = value;
    },

    onskck(value) {
      this.doc_skck = value;
    },

    onsuketsehat(value) {
      this.doc_suketsehat = value;
    },

    onskbkn(value) {
      this.doc_skbkn = value;
    },

    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
