import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    data: {
      id: '',
      golongan: {
        id: '',
        nama: '',
      },
      noperbkn: '',
      noperbkn_tgl: '',
      sk_pengangkatan: '',
      sk_tgl: '',
      status_pengangkatan: '',
      alamat: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },

    editModal(state, value) {
      state.data = value
    },

    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})