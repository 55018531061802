<template>
  <div>
    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Nota Persetujuan BKN</span>
      </v-col>

      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.noperbkn"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.noperbkn_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Pejabat Yang Menetapkan</span>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.pejabat"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Surat Keputusan (SK)</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.sk_pengangkatan"
          class="mt-2"
          hint="Nomor"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.sk_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>TMT PPPK</span>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.tmt_pppk"
          append-icon="mdi-calendar"
          class="mt-2"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Golongan</span>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field v-model="vitem.golongan.nama" outlined dense readonly>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" class="mt-3">
        <span>Status Pengangkatan</span>
      </v-col>

      <v-col cols="12" md="10">
        <v-select
          v-model="vitem.status_pengangkatan"
          class="mt-2"
          :items="stat_peng"
          item-value="id"
          item-text="nama"
          outlined
          dense
          readonly
        ></v-select>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Nomor SKCK</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.skck_nomor"
          class="mt-2"
          hint="Nomor"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.skck_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Nomor Suket Sehat</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.suketsehat_nomor"
          class="mt-2"
          hint="Nomor"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.suketsehat_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" class="mt-3">
        <span>SKBN</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.skbkn_nomor"
          class="mt-2"
          hint="Nomor"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.skbkn_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-tabs v-model="tab">
      <v-tab @click="skck(vitem.id)"> SKCK </v-tab>
      <v-tab @click="suket(vitem.id)"> Suket Sehat </v-tab>
      <v-tab @click="skbkn(vitem.id)"> SKBN </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      touchless
      class="mt-3"
      v-if="skck_pdf != '' || suket_pdf != '' || skbkn_pdf != ''"
    >
      <v-tab-item>
        <embed
          :src="skck_pdf"
          type="application/pdf"
          style="height: 80vh; width: 100%"
        />
      </v-tab-item>
      <v-tab-item>
        <embed
          :src="suket_pdf"
          type="application/pdf"
          style="height: 80vh; width: 100%"
        />
      </v-tab-item>
      <v-tab-item>
        <embed
          :src="skbkn_pdf"
          type="application/pdf"
          style="height: 80vh; width: 100%"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  props: ["vitem"],

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    tab: null,

    stat_peng: [
      {
        id: "pertama",
        nama: "Pertama",
      },
      {
        id: "perpanjangan",
        nama: "Perpanjangan",
      },
    ],

    skck_pdf: "",
    suket_pdf: "",
    skbkn_pdf: "",
  }),

  mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    skck(value) {
      const url =
        process.env.VUE_APP_API +
        "/idasn/pppk/pengangkatan/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc_skck";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.skck_pdf = URL.createObjectURL(res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    suket(value) {
      const url =
        process.env.VUE_APP_API +
        "/idasn/pppk/pengangkatan/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc_suketsehat";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.suket_pdf = URL.createObjectURL(res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    skbkn(value) {
      const url =
        process.env.VUE_APP_API +
        "/idasn/pppk/pengangkatan/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc_skbkn";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.skbkn_pdf = URL.createObjectURL(res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>
